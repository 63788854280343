import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Container from '../components/Container'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <section className="section is-midle">
          <Container>
            <div className="content">
              <h1 className="title is-size-2">{post.frontmatter.title}</h1>

              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <hr />

              <div className="level is-mobile">
                <div className="level-left noselect">
                  {previous && (
                    <Link to={'/oferta' + previous.fields.slug} rel="prev">
                      <span className="is-size-3-mobile">←</span>{' '}
                      <span className="is-hidden-mobile">
                        {previous.frontmatter.title}
                      </span>
                    </Link>
                  )}
                </div>
                <div className="level-right noselect">
                  {next && (
                    <Link to={'/oferta' + next.fields.slug} rel="next">
                      <span className="is-hidden-mobile">
                        {next.frontmatter.title}
                      </span>{' '}
                      <span className="is-size-3-mobile">→</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
